<template> 
    <div class="nav-bar-wrap">
        <van-tabbar route>
            <van-tabbar-item v-for="(item, index) in navList" :key="index" :to="`/system/${item.path}`">
                <span>{{ item.title }}</span>
                <template #icon="props">
                    <van-icon :name="item.icon" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
// import { Notify } from 'vant'
export default {
    data() {
        return {
            active: 0,
            navList: [
                { title: '数字地球', icon: 'home-o', path: 'digitalEarth' },
                { title: '智能控制', icon: 'search', path: 'intelligentControl' },
                { title: '研学实践', icon: 'friends-o', path: 'researchPractice' },

            ]
        }
    },
    methods: {
        onChange(index) {
            this.$notify({ type: 'primary', message: index})
        }
    }
};
</script>

<style lang="less" scoped>

</style>
